import React, {Fragment} from "react"

import connected from '../../images/connected.svg'
import check from '../../images/connected.svg'
class Selectable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};
    }

    click(){
        this.setState({
            clicked: !this.state.clicked,
        })
    }

    render(){
    return(
    <div onClick={() => this.click()}className={this.state.clicked ? "selectable--container active" : "selectable--container"}>
        <p className={this.state.clicked ? "selectable--text active" : "selectable--text"}>{this.props.text}</p>
        { this.props.connectIcon &&
        <img src={connected} className={this.state.clicked ? "check--icon visible" : "check--icon"} />
        }
    </div>
    );
    }
}

export default Selectable;
