import firebase from './firebase.js';
import { 
  hashString, 
} from 'react-hash-string'



  export const register2 = (email, username, password, profilePicture) => {
    // Add a second document with a generated ID.
    const db = firebase.firestore();
    db.collection("users").add({
      id: "aaaa",
      username: username,
      email: email,
      picture: profilePicture || '/default.png',
      securityHash: hashString(email+password)
    })
    .then((docRef) => {
      localStorage.removeItem('user');
      localStorage.setItem('user', hashString(email+password));
      document.cookie = "user="+hashString(email+password);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  }


  export async function userExist(email) {
    var querySize = 'aaa';
    const db = firebase.firestore();
      var users = db.collection("users");
      var users = await users.where("email", "==", email).get()
      .then((querySnapshot) => {
        //  console.log(querySnapshot)
        querySize = querySnapshot.size
        return(querySize)
      })
      return(users)
  }

  export async function getUser(securityHash) {
    const db = firebase.firestore();
      var citiesRef = db.collection("users");
      var data = null;


      return new Promise(resolve => {
        var query = citiesRef.where("securityHash", "==", parseInt(securityHash)).get()
        .then((querySnapshot) => {
          //  console.log(querySnapshot)
          if(querySnapshot.docs.length > 0){
            querySnapshot.forEach((doc) => {
              data =  doc.data();
              //  console.log('data', data)
              localStorage.setItem('user', securityHash);
              resolve(data);
            });
          } else {
            resolve(null);
          }
        })
      })
  }


  const users = [
    {
      username: ''
    }
  ]