import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDQaJ7m160fTGJFKk3wPfj3d4jOjR1u8Oo",
  authDomain: "tif-2021.firebaseapp.com",
  projectId: "tif-2021",
  storageBucket: "tif-2021.appspot.com",
  messagingSenderId: "231993019114",
  appId: "1:231993019114:web:9eaab8cedbb535a31ca593"
  };
  // Initialize Firebase
  firebase.initializeApp(config);

  export default firebase;